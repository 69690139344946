import {endpoints} from './endpoints'
import request from '../helpers/request'

export function publicidadList(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.publicidadList,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function sendPublicidad(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendAndSavePublicidad,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getPublicidadById(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.publicidadById,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}